import FormRules from "@/utils/form-rules";
import Vue from "vue";
import Component from "vue-class-component";
import { format } from "date-fns";

@Component({})
export default class RobotToken extends Vue {

  timeHandler(date: string) {
    return format(new Date(date), "yyyy-MM-dd");
  }

  dateSelectMenu = {
    expiredAt: false,
    expiredAtData: '2022-01-01',
  }

  editedIndex = -1;
  deleteItemId = -1;

  dialogEdit = false;
  dialogDelete = false;

  saveLoading = false;
  valid = false;

  formRules = FormRules.Building;
  table = {
    totalDesserts: 0,
    desserts: [],
    loading: true,
    option: {},
    headers: [
      { text: 'ID', align: 'start', value: 'id' },
      { text: '用户名', value: 'name' },
      { text: '描述', value: 'description' },
      { text: '机器码', value: 'machineCode' },
      { text: '过期时间', value: 'expirationAt' },
      { text: '创建时间', value: 'createdAt' },
      { text: '更新时间', value: 'updatedAt' },
      { text: 'Actions', value: 'actions', sortable: false }
    ]
  }

  editInfo = {
    machineCode: "",
    description: ""
  }

  mounted() {
    this.getAllInfo();

  }

  validate() {
    if ((this.$refs as any).form.validate()) {
      this.saveRobotTokenInfo();
    }
  }


  editRobotTokenInfo(item: never) {
    console.log(item);
    this.editInfo = Object.assign(item);
    this.editedIndex = this.table.desserts.indexOf(item);
    this.dialogEdit = true;
  }


  deleteRobotToken(item: any) {
    this.dialogDelete = true;
    this.deleteItemId = item.id;
  }

  saveRobotTokenInfo() {
    const _this = this;
    _this.saveLoading = true;
    _this.$axios({
      url: '/api/v1/robot/token',
      method: 'put',
      data: {
        machineCode: this.editInfo.machineCode,
        description: this.editInfo.description,
        expirationAt: new Date(Date.parse(this.dateSelectMenu.expiredAtData.replace(/-/g, "/")))
      }
    }).then(function (response) {
      _this.saveLoading = false;
      if (response.data.code === 0) {
        _this.$toast.success(response.data.message);
        _this.dialogEdit = false;
        _this.editInfo = {
          description: "",
          machineCode: ""
        };
        _this.getAllInfo();
      } else {
        _this.$toast.error(response.data.message);
      }
    }).catch(function (err) {
      _this.saveLoading = false;
      _this.$toast.error(err);
      _this.table.loading = false;
    });
  }

  getAllInfo() {
    const _this = this;
    _this.$axios({
      url: '/api/v1/robot/token/list',
      method: 'get'
    }).then(function (response) {
      if (response.data.code === 0) {
        _this.$toast.success(response.data.message);
        _this.table.loading = false;
        console.log(response.data.data);
        _this.table.desserts = response.data.data;

      } else {
        _this.$toast.error(response.data.message);
        _this.table.loading = false;

      }
    }).catch(function (err) {
      _this.$toast.error(err);
      _this.table.loading = false;

    });
  }

  deleteRobotTokenInfo() {
    const _this = this;
    _this.table.loading = true;
    _this.$axios({
      url: '/api/v1/robot/token/delete',
      method: 'delete',
      data: {
        id: _this.deleteItemId
      }
    }).then(function (response) {
      if (response.data.code === 0) {
        _this.$toast.success(response.data.message);
        _this.table.loading = false;
        _this.getAllInfo();
      } else {
        _this.$toast.error(response.data.message);
      }
    }).catch(function (err) {
      _this.$toast.error(err);
      _this.table.loading = false;
    });



  }
}